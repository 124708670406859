.column {
    display: flex;
    flex-direction: column;

    &:not(.padless) {
        padding: 10px;
    }
}

.link {
  font-size: 14px;
  color: #006CE5;
  text-decoration: underline;
  cursor: pointer;
}

.button {
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;

  width: min-content;
  padding: 8px;

  border-radius: 3px;
  font-size: 14px;

  color: #465666;
  border: 1px solid #232323;

  align-self: flex-end;
}

.textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  
  color: #465666;
  border: 1px solid #EAEAF0;
  border-radius: 3px;

  resize: none;
}

.clientRow {
  display: flex;
  align-items: center;
}

.spaceBetween {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.header {
    padding: 1rem !important;
    font-size: 16px;
}

.group {
    font-family: Arial, Helvetica, sans-serif;

    font-size: 12px;
    color: #465666;

    border: 1px solid #E0E0E0;
    border-radius: 8px;

    & legend {
        font-weight: bold;

        background-color: #EEEEEE;
        border-bottom: 1px solid #E0E0E0;

        padding: 0.5rem 1rem 0.5rem 1rem;

        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

}

.container {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto); 
    
    gap: 10px;

    & .flexible {
        grid-column: 2;
        grid-row: auto / 1;
    }

    & .flexible:last-child {
        grid-column: 2;
        grid-row: -1 / 1;
    }
}

.paddedLeft {
    padding-left: 1rem;
}

.statisticsHeader {
    text-align: center;
    padding-bottom: 0.25rem;
}
