@import "../../../assets/styles/index.scss";

.block {
    margin-top: 20px;
}

.wr_row {
    margin-top: 20px;
    position: relative;
}

.d_grid {
    display: grid;
}

.grid_gap {
    grid-gap: 30px;
}

.row_grid {}

.grid_col {}

.row_grid_col_4 {
    grid-template-columns: repeat(4, 1fr);
}

.row_grid_col_3 {
    grid-template-columns: repeat(3, 1fr);
}

.d_max {
    max-width: 1440px;
}

.d_max_768 {
    max-width: 768px;
}

.col_2 {
    grid-template-columns: 1fr 1fr;
}

.row_2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.colgap_25 {
    column-gap: 25px;
}

.fieldset {
    border: 1px solid #DEDEDE;
    border-radius: 8px;
}

.left {
    padding-right: 30px;
}

.mt_30 {
    margin-top: 30px;
}

.max_oveh {
    max-height: 360px;
    overflow: auto;
}

.right {}

.row_btn {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-end;
    width: 100%;
    border-top: 1px solid #dedede;
    padding-top: 16px;
}

.row_btn_bet {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row_btn_tow {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
}

.item_btn {
    &:not(:last-child) {
        margin-right: 16px;
    }
}

.form {}

.wr_drop {
    margin: 3px 0;
    padding-top: 4px;
}

.w_all {
    width: calc(100% - 16px);
    margin: 0 8px;
}

.row_form {
    display: flex;
    margin: 0 -8px;
}

.row_form_two {
    display: flex;
    margin: 0 -8px 0 -8px;
}

.title_in {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
}

.title_in_m {
    font-size: 14px;
    color: #000;
}

.title_input {
    font-size: 14px;
    color: #000;
    padding-bottom: 10px;
}

.title_row {
    font-size: 18px;
    color: #000;
    font-weight: 500;
}

.row_mrt {
    margin-top: 20px;
    position: relative;
}

.item_two {
    margin: 0 8px;
    width: calc(50% - 16px);
}

.row_form_gride {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;
}

.item_paylogn {
    display: flex;
}

.row_btn_pol {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
}

.row_btn_pol_left {
    padding-top: 16px;
    display: flex;
}

.col_border_r {
    border-right: 1px solid #dedede;
    padding-right: 20px;
}

.item_paylogn {
    border: 1px solid #dedede;
    padding: 9px 16px 16px;
    border-radius: 16px;

    &:not(:first-child) {
        margin-top: 16px;
    }
}

.item_paylogn_left {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.item_paylogn_right {
    padding-left: 10px;
    padding-top: 28px;
}

.btn_delete {
    background: none;
    color: $RED;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
        transform: scale(1.1);
    }
}

.wr_head {
    display: flex;
    justify-content: flex-end;
}

.d_max_1023 {
    max-width: 1023px;
}

.title_center {
    text-align: center;
}

.item_two_flex {
    flex: 1 1 auto;
    margin: 0 8px;
}

.item_two_house {
    min-width: 80px;
    margin: 0 8px;
}

.bloks {}

.ge_wr {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 300px 1fr;
}

.ge_menu {}

.ge_main {}

.row_sel {
    display: flex;
    align-items: top;
}

.btn_sel {
    margin-left: 15px;
}

.d_max_480 {
    max-width: 480px;
}

.hr {
    background: #dedede;
    margin: 15px 0;
    height: 1px;
}

.marg_bot {
    margin-bottom: 15px;
}

.items_chec {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;

    &>div {
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}

.wr_range {
    margin-top: 15px;
}

.text_info {
    text-align: center;
    color: grey;
    padding: 40px 0;
    font-size: 16px;
}

.row_item {
    margin-top: 15px;
}

.block_edit {
    position: relative;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 20px;
}

.max_he_300 {
    max-height: 315px;
    overflow: auto;
    padding-right: 10px;
}

.row_numb_field {
    display: flex;
    align-items: center;
}

.number_promo {
    padding-right: 10px;
    font-size: 16px;
    font-weight: 500;
    width: 40px;
}

.row_numb_btn {
    margin-left: 10px;
}

.title_rows {
    padding-bottom: 5px;
    font-size: 14px;
    color: #000;
}

.indentation {
    padding: 20px;
}

.indentation_left {
    padding-left: 0;
}

.indentation_right {
    padding-right: 0;
}

.border_right {
    border-right: 1px solid #dedede;
}

.header_settin {
    display: flex;
    justify-content: flex-end;
    margin-top: -7px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 16px;
}

.row_form_flex_end {
    align-items: flex-end;
}

.blocke_item {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #dedede;
}

.title_in_2 {
    font-size: 14px;
    margin-bottom: 14px;
    margin-top: 14px;
}

.title_kitchen {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 16px;
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;

    span {
        color: #eda240;
        font-weight: 600;
    }
}

.flex_center_gat_20 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    &>div {
        margin: 0;
        width: auto;
    }

    padding-bottom: 20px;
    border-bottom: 1px solid #dedede;
}

.wr_drop_bot_bor {
    padding-bottom: 20px;
    border-bottom: 1px solid #dedede;
}

.grid_row_proving {
    display: grid;
    grid-template-columns: 40px 12% 20% 18% 18% 10% 10% 24px;
    padding: 12px;
    gap: 10px;
}

.grid_row_proving_border {
    border: 1px solid #dedede;
    border-radius: 12px;

    select {
        margin-top: 0;
    }

    &:not(:first-child) {
        margin-top: 14px;
    }
}

.title_item_table_proving {
    font-weight: 600;
}

.wr_settings_proving {
    margin-top: 14px;
}

.item_table_proving_border_right {
    border-right: 1px solid #dedede;
    text-align: center;
}

.blocke_item_m_t {
    margin-top: 14px;
}

.row_form_mt {
    margin-top: 14px;
}

.title_in_min {
    font-size: 14px;
}

.two_in_s {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    align-items: flex-end;
    margin-top: 10px;

    select {
        height: 42px;
        margin-bottom: 4px;
    }
}

.delete_btn_row {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        width: 20px;
        height: 20px;
        padding: 2px;
        min-height: 20px;
    }
}

.roving_restorant {
    border: 1px solid #3e3e3e;
    padding: 14px;
    border-radius: 14px;

    &:not(:first-child) {
        margin-top: 20px;
    }
}

.banner_select {
    width: 768px;
    border-radius: 10px;
}

.d_fle_spase_and_cent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.col_itemSelect {
    display: flex;
    gap: 2px;
    align-items: center;

    div {
        &:nth-child(1) {
            flex: 1 1 auto;
        }

        &:nth-child(2) {
            width: 18px;
        }
    }
}

@media (max-width: 1025px) {
    .left {
        max-width: 100%;
    }
}